import { Link } from "react-router-dom";
import { Auth0Resource } from "../models/auth0-resource";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  const resourceList: Auth0Resource[] = [
    {
      path: "/privacy",
      label: "Privacy",
    },
    // {
    //   path: "https://auth0.com/docs/get-started",
    //   label: "How It Works",
    // },
  ];

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span></span>
              <PageFooterHyperlink path="https://auth0.com/">
                <></>
              </PageFooterHyperlink>
            </p>
            <p className="page-footer-message__description">
              <PageFooterHyperlink path="https://auth0.com/docs/quickstarts/">
                <>
                  <span>
                  </span>
                  <u></u>
                </>
              </PageFooterHyperlink>
            </p>
          </div>

          <div className="page-footer-info__resource-list">
            {
              resourceList.map(
                (resource) => (
                  <div
                    key={resource.path}
                    className="page-footer-info__resource-list-item"
                  >
                    <Link to={resource.path}>
                      {resource.label}
                    </Link>

                    {
                      /*
                        <PageFooterHyperlink path={resource.path}>
                          <>{resource.label}</>
                        </PageFooterHyperlink>
                      */
                    }
                  </div>
                )
              )
            }
          </div>
        </div>

        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <Link to="/" id="brand-logo">
              <img
                className="page-footer-brand__neh_logo"
                src="https://raw.githubusercontent.com/neh-cli/neh/main/logo/logo_white.png"
                alt="Neh"
                width="22"
                height="22"
              />

              <>Neh</>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
