// Website used as reference
// https://otter.ai/pricing
// icons: https://heroicons.com/
//
// * Trial
// * Basic
// * Proffesional
// * Business

import { useAuth0 } from "@auth0/auth0-react";
import {
  useEffect,
  useState,
} from "react";

import { Plan } from "src/components/product";
import { PageLayout } from "../components/page-layout";
import { getProductsResource } from "../services/message.service";
import { Product } from "../models/product";

export const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data: products, error } = await getProductsResource(accessToken);

      if (products) setProducts(products);
      if (error) console.log({ error });
    };

    getMessage();
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">
          Plans
        </h1>

        <div className="auth0-features__grid">
          {
            products.map(
              product => (
                <Plan
                  key={product.product_id}
                  product={product}
                />
              )
            )
          }
        </div>
      </div>
    </PageLayout>
  );
};
