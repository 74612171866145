import { Link } from "react-router-dom";

interface PlanProps {
  product: any;
}

export const Plan: React.FC<PlanProps> = ({
  product,
}) => {
  console.log({ product });

  return (
    <Link
      to={`/plans/${product.product_id}`}
      className="auth0-feature"
    >
      <h3 className="auth0-feature__headline">
        <img
          className="auth0-feature__icon"
          src={`/icons/${product.plan_code}.svg`}
          alt="external link icon"
        />
        {product.name}
      </h3>

      <p className="auth0-feature__description">{product.description}</p>
    </Link>
  )
};
