// src/pages/settings-page.tsx
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegCopy, FaSync, FaTrash } from "react-icons/fa";
import { useAuthToken } from "../hooks/useAuthToken";
import { PageLayout } from "../components/page-layout";
import { createPersonalAccessToken, deletePersonalAccessToken, fetchPersonalAccessTokens } from "../services/message.service";

export const SettingsPage: React.FC = () => {
  const { user } = useAuth0();

  const auth0AccessToken = useAuthToken();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [personalAccessTokenDetailsDisplayed, setPersonalAccessTokenDetailsDisplayed] = useState(false);
  const [personalAccessToken, setPersonalAccessToken] = useState<string | null>(null);
  const [personalAccessTokenId, setPersonalAccessTokenId] = useState<number | null>(null);

  if (!user) return null;

  const handleCreatePersonalAccessToken = async () => {
    if (!auth0AccessToken) {
      console.error('auth0AccessToken is NOT defined.');
      return;
    }

    const actionName = personalAccessToken ? 'Update' : 'Create';
    const confirmed =
      window.confirm(`${actionName} Personal Access Token?`);
    if (!confirmed) return;

    const { data, error } = await createPersonalAccessToken(auth0AccessToken);
    const token = data.token;
    setPersonalAccessToken(token);
    setPersonalAccessTokenId(data.tokenId);
    if (error) console.error('Error canceling subscription:', error);
  };

  const handleShowCreateButton = async () => {
    const { data } = await fetchPersonalAccessTokens(auth0AccessToken);
    const personalAccessToken = data.token;
    setPersonalAccessToken(personalAccessToken);
    setPersonalAccessTokenId(data.tokenId);
    setPersonalAccessTokenDetailsDisplayed(true);
  };

  const handleDeletePersonalAccessToken = async () => {
    if (!auth0AccessToken || !personalAccessToken) {
      console.error('Insufficient information to delete.');
      return;
    }

    const confirmed = window.confirm('Delete Personal Access Token?');
    if (!confirmed) return;

    const { error } = await deletePersonalAccessToken(auth0AccessToken, personalAccessTokenId);
    if (error) {
      console.error('Error deleting token:', error);
      return;
    }

    setPersonalAccessToken(null);
    setPersonalAccessTokenDetailsDisplayed(false);
  };


  return (
    <PageLayout>
      <div className="auth0-features">
        <h1 className="neh-features__title">
          Settings
        </h1>

        <div className="content__body">
          <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
          </div>

          <div className="profile-grid">
            <div className="profile__header">
              <div className="profile__headline">
                <h2 className="profile__title">Personal Access Token</h2>

                <div
                  className="profile__description"
                >
                  By using a Personal Access Token, it is possible to communicate with the Neh server. Only one token can be created per user.
                </div>
              </div>
            </div>

            <div>
              {
                !personalAccessTokenDetailsDisplayed && (
                  <button
                    onClick={handleShowCreateButton}
                    className="button button--secondary button--compact"
                  >
                    Show Details
                  </button>
                )
              }

              {
                personalAccessToken && (
                  <div>
                    <span>Personal Access Token: </span>

                    <pre>
                      {
                        `${personalAccessToken.slice(0, 4)}..........${personalAccessToken.slice(-4)}`
                      }
                    </pre>

                    <button
                      onClick={
                        async () => {
                          setTooltipVisible(true);

                          try {
                            await navigator.clipboard.writeText(personalAccessToken);
                            setTimeout(() => setTooltipVisible(false), 2000);
                          } catch (error) {
                            console.error('Failed to copy to clipboard:', error);
                          }
                        }
                      }
                    >
                      <FaRegCopy />
                      {
                        tooltipVisible && (
                          <span className="tooltip"> Personal Access Token copied to clipboard.</span>
                        )
                      }
                    </button>

                    <button
                      onClick={ handleCreatePersonalAccessToken }
                    >
                      <FaSync />
                    </button>

                    <button
                      onClick={ handleDeletePersonalAccessToken }
                    >
                      <FaTrash />
                    </button>

                  </div>
                )
              }

              {
                !personalAccessToken &&
                  personalAccessTokenDetailsDisplayed && (
                    <div>
                      <button
                        id="create-personal-access-token-button"
                        onClick={handleCreatePersonalAccessToken}
                        className="button button--secondary button--compact"
                      >
                        Create
                      </button>
                    </div>
                )
              }
            </div>

          </div>
        </div>
      </div>
    </PageLayout>
  );
};
