import React from "react";
import { NavLink } from "react-router-dom";

interface MobileNavBarBrandProps {
  handleClick: () => void;
}

export const MobileNavBarBrand: React.FC<MobileNavBarBrandProps> = ({
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__neh_logo"
          src="https://raw.githubusercontent.com/neh-cli/neh/main/logo/logo_white.svg"
          alt="Neh logo"
          width="52"
        />
      </NavLink>
    </div>
  );
};
