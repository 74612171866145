// src/pages/product-page.tsx

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { PageLoader } from "../components/page-loader";
import { PageLayout } from "../components/page-layout";
import { useAuthToken } from "../hooks/useAuthToken";
import { useProduct } from "../hooks/useProduct";
import { cancelSubscription, createCheckoutSession } from "../services/message.service";

export const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const ProductPage: React.FC = () => {
  const { product_id } = useParams<{ product_id: string }>();
  const [ productUrl, setProductUrl ] = useState<string | null>(null);

  const auth0AccessToken = useAuthToken();

  const product = useProduct(auth0AccessToken, product_id);

  useEffect(() => {
    const initiateCheckoutSession = async () => {
      if (!auth0AccessToken || !product_id) {
        console.error('auth0AccessToken or product_id is NOT defined.');
        return;
      }

      const { data, error } =
        await createCheckoutSession(auth0AccessToken, product_id);

      if (data.url) {
        setProductUrl(data.url);
      } else {
        console.error('Error creating checkout session:', error);
      }
    };

    if (auth0AccessToken && product_id) {
      initiateCheckoutSession();
    }
  }, [auth0AccessToken, product_id]);

  if (!product) return <div className="page-layout"><PageLoader /></div>;

  const formattedPeriodEnd = new Date(product.current_period_end * 1000).toLocaleString();

  const handleCancelSubscription = async () => {
    if (!auth0AccessToken) {
      console.error('auth0AccessToken is NOT defined.');
      return;
    }

    const confirmed = window.confirm('Are you sure you want to cancel all your subscriptions?');
    if (!confirmed) {
      return;
    }

    const { error } = await cancelSubscription(auth0AccessToken);

    if (error) {
      console.error('Error canceling subscription:', error);
    } else {
      // 今後の課題
      // 以下を、window.location.reload() をするのではなく、このProductPage コンポーネントの再評価を行うように変更する
      window.location.reload();
    }
  };

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          {product.name}
        </h1>
        <div className="content__body">
          <div id="page-description">
            <div>
              <div>
                <p>{product.description}</p>
                {
                  product.active && (
                    <p>Valid until { formattedPeriodEnd }</p>
                  )
                }

                {
                  product.active &&
                    !product.cancel_at_period_end &&
                      <button
                        onClick={handleCancelSubscription}
                        className="button button--secondary button--compact"
                      >Cancel</button>
                }

                {
                  product.active &&
                    product.cancel_at_period_end &&
                      <p>The subscription will be automatically deactivated at the end of the current period. To continue usage beyond this period, a new purchase will be required upon expiration.</p>
                }

                {
                  !product.active && productUrl &&
                    <Link
                      to={`${productUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button--secondary button--compact"
                    >
                      Purchase this
                    </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
