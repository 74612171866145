import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { Top } from "./pages/top";

import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";

import { ProductPage } from "./pages/product-page";
import { ProductsPage } from "./pages/products-page";

import { PrivacyPage } from "./pages/privacy-page";
import { SettingsPage } from "./pages/settings-page";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<HomePage />}
      />

      <Route
        path="/home"
        element={<HomePage />}
      />

      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />

      <Route
        path="/privacy"
        element={<PrivacyPage />}
      />

      <Route
        path="/plans"
        element={<AuthenticationGuard component={ProductsPage} />}
      />

      <Route
        path="/plans/:product_id"
        element={<AuthenticationGuard component={ProductPage} />}
      />

      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      />

      <Route
        path="/settings"
        element={<AuthenticationGuard component={SettingsPage} />}
      />

      <Route path="/callback" element={<CallbackPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
